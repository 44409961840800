#services {
  padding: 30px 0;

  .container {
    padding: 10px var(--paddingX);

    h1 {
      font-size: 45px;
      font-weight: 600;
    }

    .services-list {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      // gap: 30px;
      margin-top: 50px;

      .services-swiper {
        border-radius: 10px;
        .swiper-button-next,
        .swiper-button-prev {
          &::after {
            color: rgb(144, 144, 144);
            font-size: 1.4rem;
          }
        }

        .swiper-pagination-horizontal {
          display: none;
          @include xl {
            display: block;
          }
          @include mobile {
            display: block;
          }
        }
      }
    }
  }
}
