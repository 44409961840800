.learn-more-container {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1d6fa3;

  &--title {
    max-height: fit-content;
    font-weight: 700;
    font-size: 16px;
    font-family: "DM Sans", sans-serif;
    color: inherit;
    text-decoration: none;
  }

  &--arrow-forward {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }
}
