@import "../../css-config/mixins.scss";

#contact {
  .container {
    padding: 10px var(--paddingX);

    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .contact-left {
        flex-basis: 35%;
        @include mobile {
          flex-basis: 100%;
        }

        h1 {
          font-size: 40px;
          font-weight: 600;
        }

        p {
          margin-top: 30px;

          i {
            margin-right: 15px;
            font-size: 22px;
            color: #ff004f;
          }
        }

        .social-icons {
          margin-top: 30px;

          a {
            margin-right: 15px;
            font-size: 25px;
            display: inline-block;
            color: #1d1c1c;
            transition: 0.5s;

            &:hover {
              color: #ff004f;
              transform: translateY(-5px);
            }
          }
        }
      }

      .contact-right {
        flex-basis: 60%;
        @include mobile {
          flex-basis: 100%;
        }

        form {
          width: 100%;

          .recaptcha {
            margin-top: 20px;
          }

          input,
          textarea {
            width: 100%;
            border-radius: 6px;
            margin: 10px 0;
            padding: 15px;
            font-size: 18px;
            outline: none;
            border: 0;
            // background: #d9d8e0;
            background: var(--cardBgColor);
          }

          .btn2 {
            padding: 14px 60px;
            font-size: 18px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.btn {
  display: block;
  margin: 50px auto;
  border: 1.6px solid #ff004f;
  width: fit-content;
  text-decoration: none;
  padding: 14px 50px;
  border-radius: 6px;
  color: #000;
  transition: background-color 0.3s;
  @include mobile {
    background-color: #ff004f;
    color: #fff;
  }

  &:hover {
    background-color: #ff004f;
    color: #fff;
  }

  &.btn2 {
    display: inline-block;
  }
}

.disable-btn {
  background-color: #c1bfbf;
  color: #efeeee;
  border: none;
  &:hover {
    background-color: #dfdede;
  }
}

.enable-btn {
  border: 1.6px solid #ff004f;
  background-color: #ff004f;
  color: #fff;
  @include mobile {
    background-color: #ff004f;
    color: #fff;
  }
}
