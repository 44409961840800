#portfolio {
  padding: 50px 0;

  .container {
    padding: 10px var(--paddingX);

    h1 {
      font-size: 40px;
      font-weight: 600;
    }

    .work-list {
      margin-top: 50px;

      .projects-swiper {
        border-radius: 15px;
        .swiper-button-next,
        .swiper-button-prev {
          &::after {
            color: white;
            font-size: 2rem;
          }
        }

        .swiper-pagination-horizontal {
          display: none;
          @include xl {
            display: block;
          }
          @include mobile {
            display: block;
          }
        }
      }
    }
  }
}
