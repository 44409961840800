.services-card {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  background: var(--cardBgColor);
  padding: 40px 50px;
  font-weight: 300;
  border-radius: 10px;
  transition: background-color 0.5s, transform 0.5s;
  cursor: pointer;

  &-desc {
    color: #808080;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }

  &:hover {
    background-color: #ff004f;
    transform: translateY(-5px);
    color: #fff;
    .services-card-desc,
    .learn-more-container--arrow-forward,
    a {
      color: white;
    }
  }

  i {
    font-size: 80px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &-learn-more {
    margin-top: auto;
    .learn-more-container {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #1d6fa3;

      &--title {
        max-height: fit-content;
        font-weight: 700;
        font-size: 16px;
        font-family: "DM Sans", sans-serif;
        color: inherit;
        text-decoration: none;
      }

      &--arrow-forward {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bolder;
      }
    }
  }
}
