.project-card {
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  height: 580px;

  @include mobile {
    height: 500px;
  }

  &--project-image {
    object-fit: cover;
    min-height: 100%;
    border-radius: 10px;
    width: 100%;
    transition: all ease 0.5s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &-layer {
    background: rgba(75, 75, 75, 0.5);
    color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    // padding: 20px 40px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 14px;

    &--top-content {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &--bottom-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      bottom: 10%;
      left: 6%;

      &--project-title {
        padding-right: 20%;
        font-weight: 500;
        margin-bottom: 20px;
      }

      &--btn-container {
        margin-top: auto;
        display: flex;
        gap: 10px;
        width: fit-content;
        text-align: center;

        a {
          text-decoration: none;
          font-size: 14px;
          background: #fff;
          color: #ff004f;
          padding: 5px 10px;
          border-radius: 10px;
          margin-top: 20px;
          width: 70px;
          cursor: pointer;
        }
      }

      &--tech-used-container {
        display: flex;
        padding-right: 20%;
        flex-wrap: wrap;
        gap: 10px;

        &--tech {
          width: max-content;
          padding: 4px 10px;
          border-radius: 20px;
          background-color: rgb(124, 123, 123);
        }
      }
    }
  }
}
