@import "../../css-config/mixins.scss";

#about {
  padding: 30px 0;
  /* color: #09db25; */

  .container {
    padding: 10px var(--paddingX);

    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .about-col-1 {
        flex-basis: 35%;
        &-about-img {
          height: 830px;
          object-fit: cover;
        }
        @include mobile {
          flex-basis: 100%;
          margin-bottom: 30px;
        }

        img {
          width: 100%;
          border-radius: 10px;
          @include mobile {
            display: none;
          }
        }
      }

      .about-col-2 {
        flex-basis: 55%;
        @include mobile {
          flex-basis: 100%;
          font-size: 18px;
        }

        h1 {
          font-size: 50px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        p {
          text-align: justify;
          @include mobile {
            font-size: 16px;
          }
        }

        .mobile-about-img {
          display: none;
          @include mobile {
            display: block;
            width: 100%;
            border-radius: 10px;
            margin-top: 25px;
          }
        }

        .about-titles {
          display: flex;
          margin: 20px 0px 40px;

          .about-links {
            margin-right: 50px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            position: relative;
            @include mobile {
              font-size: 16px;
              margin-top: 25px;
              margin-right: 15px;
            }

            &:after {
              content: "";
              height: 3px;
              width: 0;
              background-color: #ff004f;
              position: absolute;
              left: 0;
              bottom: -8px;
              transition: 0.3s;
            }

            &:hover::after {
              width: 100%;
            }

            &.active-link::after {
              width: 50%;
            }
          }
        }

        .about-tab-contents {
          display: none;

          &.active-tab {
            display: block;
          }

          .certification li {
            cursor: pointer;
            transition: all ease 0.5s;
            &:hover {
              transform: translateY(2px);
            }
          }

          ul li {
            list-style: none;
            margin: 10px 0;

            span {
              color: #ff004f;
            }
          }
        }
      }
    }
  }
}
